import { FC } from 'react';

interface DateProps {
    date: string;
    format?: string;
}

const formats = {
    full: (date: string) => {
        return date.replace('T', ' ').slice(0, 16);
    },
    date: (date: string) => {
        return date.slice(0, 10);
    },
    time: (date: string) => {
        return date.slice(11, 16);
    },
    short: (date: string) => {
        const dateParts = date.split('T')[0].split('-');
        const timeParts = date.split('T')[1].split(':');
        return `${dateParts[1]}/${dateParts[2]} ${timeParts[0]}:${timeParts[1]}`;
    }
}

export const Date: FC<DateProps> = ({ date, format }) => {

    return (
        <span>{(formats as any)[format || 'short'](date)}</span>
    );
}