import { FC, useState } from 'react';
import { Date } from './Date';
import { IconSearch, IconTrash, IconUser } from './Icons';

interface ProfileListItemProps {
    index: number;
    profile: any;
    onShowDetails: (key: number) => void;
    showDetails: boolean;
    onDelete: (cid: string) => void;
}

export const ProfileListItem: FC<ProfileListItemProps> = ({ index, profile, onShowDetails, showDetails, onDelete}) => {
    const [details, setDetails] = useState<boolean>(showDetails);
    return (
        <>
        <tr onClick={() => onShowDetails(index)}>
            <td>
                <IconUser />
            </td>
            <td>
                {(profile as any).i?.map((ident: any, idx: number) => {
                    return <div><span key={idx}>{ident.id_type}: {ident.id_value}</span></div>
              })}
            </td>
            <td>
                {(profile as any).s?.map((seg: any, idx: number) => {
                    return <span><span key={idx} className='tag is-warning'>{seg}</span> &nbsp;</span>
                })}
            </td>
            <td>
                <Date date={profile.ctime} format="short" />
            </td>
            <td>
                <Date date={profile.mtime} format="short" />
            </td>
            <td>
                <button className='button has-text-primary' onClick={() => {onShowDetails(index); setDetails(true)}}><IconSearch /></button>
            </td>
        </tr>
            {details &&
                <tr>
                    <td colSpan={6}>
                        <button className='button has-text-danger' onClick={() => {onDelete(profile.cid); setDetails(false)}}><IconTrash /></button>
                        <pre>{JSON.stringify(profile, null,2)}</pre>
                    </td>
                </tr>
            }
        </>
    );
}